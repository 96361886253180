import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      title:
        "Welcome to Rentcloud, Innovative Property Management Software in Kenya. Efficient Property Management System in Kenya| RentCloud",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/AboutView.vue"),
    meta: {
      title: "About RentCloud | RentCloud",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/ContactView.vue"),
    meta: { title: "Contact RentCloud | RentCloud" },
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/ServicesView.vue"),
    meta: { title: "Services Offered by RentCloud | RentCloud" },
  },
  {
    path: "/careers",
    name: "Careers",
    component: () => import("../views/CareersView.vue"),
    meta: { title: "Careers at RentCloud | RentCloud" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/TermsView.vue"),
    meta: { title: "Terms of Use | RentCloud" },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/PrivacyView.vue"),
    meta: { title: "Privacy Policy | RentCloud" },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../components/NotFound.vue"),
    meta: { title: "404 | RentCloud" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
