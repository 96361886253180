<template>
  <div class="mb-0">
    <footer
      class="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top bg-white"
    >
      <div class="footer-logo">
        <img width="150" src="../assets/images/rentcloud-logo-white.jpg" />

        <!-- <p class="col-md-4 mb-0 text-muted">&copy; 2022 Company, Inc</p> -->
        <p class="mt-2 p-2">
          <br />
          Buruburu Shopping Centre <br />
          Mumias S. Rd., Buruburu<br />
          Nairobi, Kenya.
        </p>
      </div>

      <ul class="nav col-md-4 justify-content-end">
        <li class="nav-item">
          <router-link to="/" class="text-decoration-none text-muted"
            >Home</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/services" class="text-decoration-none text-muted"
            >Services</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/contact" class="text-decoration-none text-muted"
            >Contact</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/about" class="text-decoration-none text-muted"
            >About</router-link
          >
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style>
ul > li.nav-item {
  padding: 0.25rem;
}
@media screen and (max-width: 600px) {
  .footer-logo {
    display: none;
  }
  .nav-link {
    padding-left: 0;
    padding-right: 0.125rem;
  }
  .nav-item {
    padding: 1rem;
    margin: 0;
  }
}
</style>
