<template>
  <div class="rentcloud">
    <nav class="navbar navbar-expand-lg mb-0 py-4 px-5">
      <div
        class="container container-fluid main-nav d-flex justify-content-between"
      >
        <div class="top-brand-logo">
          <img
            width="150"
            src="./assets/images/rentcloud-logo-white.jpg"
            class="#"
          />
        </div>

        <div class="top-nav-items">
          <router-link to="/">Home</router-link>
          <router-link to="/services">Services</router-link>
          <router-link to="/careers">Careers</router-link>
          <router-link to="/about">About</router-link>
          <router-link to="/contact">Contact</router-link>
        </div>
      </div>
      <div class="hamburger-menu mt-4">
        <div class="hamburger-items" v-if="showMenu">
          <router-link to="/" @click="closeMenu">Home</router-link>
          <router-link to="/services" @click="closeMenu">Services</router-link>
          <router-link to="/careers" @click="closeMenu">Careers</router-link>
          <router-link to="/about" @click="closeMenu">About</router-link>
          <router-link to="/contact" @click="closeMenu">Contact</router-link>
        </div>
      </div>
      <div class="hamburger-menu hamburger-menu-bars">
        <span
          v-if="!showMenu"
          class="fa fa-bars d-inline-block fs-4 p-2 mt-2"
          @click="openMenu"
        ></span>
        <span
          v-if="showMenu"
          class="fa fa-close d-inline-block p-2 mt-2"
          @click="closeMenu"
        ></span>
      </div>
    </nav>
    <router-view />
    <PageFooter class="footer" />
  </div>
</template>
<style>
@import "./assets/fonts/font-awesome-4.7.0/css/font-awesome.css";

html {
  scroll-behavior: smooth;
}

li {
  list-style-type: none;
  padding: 20px;
}
:root {
  --white: #fff;
  --backgroundColor: #fff;
  --red: #ff0000;
}
.bg-basic {
  background: #00091b;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  background-color: var(--backgroundColor);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: #fff !important;
  overscroll-behavior: none;
}
.image-wrap {
  display: flex;
  flex-wrap: wrap;
}
nav {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--white);
  width: 100vw;
  overflow: hidden;
  z-index: 99;
  box-shadow: 0 2px 2px rgba(94, 92, 92, 0.1);
}
.top-brand-logo {
  padding: 0;
  margin: 0;
}
nav a {
  font-size: 1rem;
  font-weight: bold;
  background-color: var(--white);
  color: #333;
  text-decoration: none;
  padding: 20px;
}
nav a.router-link {
  text-decoration: none;
}
nav a.router-link-exact-active {
  color: #006c36;
}
nav .list-item {
  justify-content: space-between;
}
a.list-item {
  text-decoration: none;

  background: var(--white);
  padding: 1rem;
  margin: 2px;
  border-radius: 0.5rem;
  width: 400px;
  display: block;
}
a.list-item:hover {
  background: #333;
  color: var(--white);
}
.main-content {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.welcome-page {
  background-color: transparent;
  margin: 20px 0 0 0;
  padding: 0;
  color: #fff;
  font-size: 2.9rem;
}
.footer {
  position: relative;
  bottom: 0;
}
.hamburger-menu {
  display: none;
}
@media screen and (max-width: 600px) {
  nav a {
    padding: 0.8rem;
    margin: 0;
  }
  .top-nav-items {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
  .hamburger-menu-bars {
    display: inline;
    position: absolute;
    left: 1rem;
    top: 1.4rem;
  }
  .hamburger-menu-bars span:hover,
  .hamburger-menu-bars span:focus {
    background: #eee;
    color: #000;
    cursor: pointer;
    border-radius: 50% 50%;
  }
  .hamburger-menu .hamburger-items {
    display: flex;
    flex-direction: column;
  }
}
</style>
<script>
import PageFooter from "./components/PageFooter.vue";
export default {
  components: {
    PageFooter,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    openMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
  },
};
</script>
