<template>
  <div class="container home">
    <!-- <div class="background-img d-flex justify-content-center mx-auto">
      <img src="../assets/images/background-light-4.svg" class="bg-img" />
      <h1 class="welcome-page my-5 text-dark-green">
        For all Your Property <br />
        Management Needs
      </h1>
      <div id="typing"></div>
    </div> -->
    <div class="row background-img">
      <div class="col-md-12">
        <div class="card text-white">
          <div class="card-img-overlay"></div>
        </div>
        <img
          src="./../assets/images/home-image.jpg"
          class="card-img"
          alt="Main image"
          width="100%"
        />
      </div>
    </div>
    <div class="welcome-massage mt-5 p-3 shadow-sm">
      <h1 class="mx-auto mt-5 py-4 text-dark-green welcome-rentcloud">
        Welcome to <span class="text-dark-green fw-bold">Rent</span
        ><span class="text-dark fw-lighter">Cloud</span
        ><span class="text-dark-green">.</span>
      </h1>
      <p class="fade-in-from-bottom mb-3 text-center mx-auto pb-4">
        RentCloud software enhances property management efficiency, reduces
        manual effort, and provides landlords and property managers with the
        tools needed to manage their properties effectively and intelligently.
        Manage your properties with ease using RentCloud's powerful tools for
        rent collection, tenant communication, and maintenance coordination.
      </p>
    </div>

    <div class="row my-5">
      <section class="d-flex mx-auto">
        <h1 class="newt text-center fade-in-from-bottom">
          Stressfree Property Management with RentCloud
        </h1>
        <div class="newt text-center fade-in-from-bottom">
          Managing tenants and collecting rent has never been easier!
        </div>
      </section>
    </div>
    <home-page></home-page>
  </div>
</template>
<script>
import HomePage from "@/components/HomePage.vue";

export default {
  // metaInfo: {
  //   script: [
  //     {
  //       src: "https://unpkg.com/typewriter-effect@latest/dist/core.js",
  //       async: true,
  //       defer: true,
  //     },
  //   ],
  // },

  components: {
    HomePage,
  },

  methods: {
    typeWriting() {
      // var typing = document.getElementById("typing");
      // var typewriter = new Typewriter(typing, {
      //   loop: true,
      // });
      // typewriter
      //   .typeString("Web Developer,<br> ")
      //   .pauseFor(2500)
      //   .typeString("Web Designer,<br>")
      //   .pauseFor(2500)
      //   .typeString("<strong>FrontEnd Developer</strong>")
      //   .pauseFor(2500)
      //   .start();
    },
  },
};
</script>
<style>
section {
  display: flex;
  flex-direction: column;
  background-color: rgb(10, 35, 119);
  /* background: #4d8ccf; */
  padding: 100px 50px;
  color: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
}
section .newt {
  background-color: rgb(10, 35, 119);
  /* background: #4d8ccf; */
}
.bg-basic {
  background: #00091b;
}
.subscribe {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 100px 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.subscribed {
  background-color: #fff;
  color: #444;
}
.subscribe-email {
  display: flex;
  border-radius: 6px;
  margin-bottom: 50px;
  margin-top: 15px;
  width: 100%;
  max-width: 450px;
}
.subscribe-email > input {
  outline: none;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 15px 0 0 15px;
}
.subscribe-email button {
  background-color: rgb(10, 35, 119);
  color: #fff;
  border-radius: 0 15px 15px 0;
}
.background-img {
  z-index: 10;
  width: 100%;
  height: 60vh;
}
.welcome-page {
  display: flex;
  position: absolute;
  top: 15rem;
  justify-content: left;
  font-size: 4rem;
  line-height: 1.5;
  font-weight: 400;
}

.welcome-massage {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.welcome-massage h1 {
  font-size: 4rem;
}
.welcome-massage p {
  width: 90%;
  max-width: 960px;
}
.bg-img {
  width: 100%;
  object-fit: cover;
}
.card-img {
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  .welcome-page {
    font-size: 3rem;
    position: absolute;
    top: 14rem;
    left: 2rem;
  }
  .welcome-massage {
    display: flex;
    position: absolute;
    width: 100%;
    left: 50%;
  }
  .welcome-massage h1 {
    font-size: 2rem;
  }
}
#typing {
  text-align: center;
  font-size: 50px;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-clip: border-box;
  text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>
