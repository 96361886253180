<template>
  <div class="#">
    <section class="hero bg-white">
      <div class="container text-center text-dark fade-in-from-bottom">
        <h1>RentCloud Property Management Tool</h1>
        <p>Your Complete Property Management Solution</p>
        <div class="features d-flex justify-content-evenly my-5 py-3">
          <h6 class="online-payment">
            <span class="fa fa-money"></span><br />Real-time Online Payments
          </h6>
          <h6 class="online-payment">
            <span class="fa fa-bank"></span><br />Flexible Payment Plan
          </h6>
          <h6 class="online-payment">
            <span class="fa fa-phone"></span><br />Professional Support
          </h6>
        </div>
        <a href="#features" class="btn bg-royal-blue text-white btn-lg"
          >Learn More</a
        >
      </div>
    </section>
    <section class="bg-light py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 bg-light">
            <img
              src="../assets/images/money-dark-green.svg"
              alt="Image Description"
              class="img-hide bg-transparent fade-in-from-left"
              height="300px"
            />
          </div>
          <div class="col-md-6 bg-light text-dark fade-in-from-right appear">
            <h2 class="mt-4 bg-light text-dark-green">
              Integrated Payment System
            </h2>
            <p class="bg-light">
              RentCloud's integrated payment system facilitates seamless rent
              collection and payments. It supports a variety of payment methods,
              including bank transfers and M-Pesa. Tenants can conveniently make
              payments using their preferred method, and landlords receive the
              funds directly into their accounts. This feature ensures
              transparency, reduces cash handling, and enhances the overall
              payment experience.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-dark">
            <h2 class="mb-3 text-royal">Lease Management</h2>
            <p class="bg-white fade-in-from-top delay-1">
              RentCloud's lease management feature simplifies the process of
              creating, storing, and managing leases for different properties.
              Landlords and property managers can easily generate lease
              agreements tailored to specific properties and tenants. The
              feature enables you to set lease terms, rental rates, start and
              end dates, and any special clauses. It also provides options to
              digitally sign leases, ensuring a secure and paperless process.
            </p>
          </div>
          <div class="col-md-6">
            <img
              src="../assets/images/lease.svg"
              alt="Image Description"
              height="300px"
              width="100%"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="bg-light py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 bg-light">
            <img
              src="../assets/images/reminder-orange.svg"
              alt="Image Description"
              class="img-hide bg-transparent"
              height="300px"
              width="100%"
            />
          </div>
          <div class="col-md-6 bg-light text-dark">
            <h2 class="mb-3 bg-light text-orange">Automated Reminders.</h2>
            <p class="bg-light">
              With automated reminders, RentCloud ensures that important tasks
              and deadlines are never missed. Landlords and property managers
              can set up reminders for rent payments, lease renewals, property
              inspections, and maintenance appointments. These reminders can be
              sent via email or SMS, helping both tenants and property managers
              stay organized and on top of crucial dates.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-dark">
            <h2 class="mb-3 text-royal">Insightful Reports</h2>
            <p class="bg-white">
              RentCloud's insightful reports provide valuable data and analytics
              that help landlords and property managers make informed decisions.
              These reports offer a comprehensive view of key performance
              indicators (KPIs) such as occupancy rates, rental income, tenant
              turnover, and maintenance history. Landlords can use these reports
              to assess property performance, identify trends, and strategize
              for growth. Additionally, the ability to customize reports allows
              users to focus on specific areas of interest.
            </p>
          </div>
          <div class="col-md-6">
            <img
              src="../assets/images/reports.svg"
              alt="Image Description"
              height="300px"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- Add more sections with similar structure for different features -->

    <section class="bg-primary text-dark py-5">
      <div class="container bg-primary text-center my-5">
        <h2 class="bg-primary text-light">Get Started with RentCloud Today</h2>
        <p class="bg-primary text-light">
          Experience the future of property management. Join our growing
          community of satisfied landlords and property managers.
        </p>
        <a href="contact" class="btn btn-light btn-lg">Tell me more..</a>
      </div>
    </section>
    <section id="features" class="my-4 py-5" style="background-color: #fff">
      <div class="container" style="color: #333">
        <div class="row">
          <div class="col-md-6">
            <h2 class="p-2 mb-3 rounded text-dary">
              Effortless Property Management
            </h2>

            <ul class="p-1">
              <li class="card mb-2 text-secondary fade-in-from-left delay-1">
                <span
                  class="text-dark-green fs-3 p-2 rounded fa fa-paper-plane"
                ></span>
                <strong class="text-dark-green">Streamlined Operations.</strong>
                RentCloud simplifies property management tasks by providing a
                user-friendly interface that centralizes all property-related
                activities.
              </li>
              <li class="card mb-2 text-secondary fade-in-from-left delay-2">
                <span class="fs-3 p-2 rounded fa fa-wrench text-royal"></span>
                <strong class="text-royal">Intuitive Tools.</strong>
                Access powerful tools for lease creation, tenant communication,
                and maintenance coordination, enhancing operational efficiency.
              </li>
              <li
                class="card mb-2 text-secondary fade-in-from-left delay-3 d-flex"
              >
                <span
                  class="bg-white fs-3 p-2 rounded fa fa-file text-yellow"
                ></span>
                <strong class="text-yellow">Paperless Workflow.</strong>
                Eliminate paperwork with digital lease agreements, document
                storage, and online communication, saving time and reducing
                clutter.
              </li>
              <li class="card mb-2 text-secondary fade-in-from-left delay-4">
                <span
                  class="text-light-green fs-3 p-2 rounded fa fa-line-chart"
                ></span>
                <strong class="text-light-green">Enhanced Visibility.</strong>
                Gain real-time insights into property performance, tenant
                interactions, and financial metrics, enabling informed
                decision-making.
              </li>
              <li class="card mb-2 text-secondary fade-in-from-left delay-5">
                <span class="fs-3 p-2 rounded fa fa-mobile text-orange"></span>
                <strong class="text-orange">Mobile Access.</strong>
                Manage properties on-the-go with RentCloud's mobile-friendly
                interface, ensuring flexibility and convenience.
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <img
              src="../assets/images/blob-dark-green.svg"
              alt="Image Description"
              class="img-hide"
              width="100%"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  created() {
    this.setUpIntersectionObserver();
  },
  methods: {
    // Use the Intersection Observer API to detect when elements are in the viewport
    setUpIntersectionObserver() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Add a class to trigger the CSS animation when an element is in the viewport
              entry.target.classList.add("appear");
              //              observer.unobserve(entry.target); // Unobserve once the animation is triggered
            }
          });
        },
        { threshold: 0.2 } // Adjust the threshold as needed, this value means 20% of the element is visible
      );

      // Observe each element you want to reveal
      const elements = document.querySelectorAll(
        ".fade-in-from-top, .fade-in-from-left, .fade-in-from-right, .fade-in-from-bottom, .delay-1"
      );
      elements.forEach((element) => {
        observer.observe(element);
      });
    },
  },
};
</script>

<style>
.features h6 span {
  color: #1a5a94;
  /* color: #007f5f; */
  font-size: 3rem;
  margin-bottom: 2rem;
}
h5 {
  margin-top: 2rem;
}
.bg-royal-blue {
  background: #1a5a94;
}
.bg-dark-green {
  background: #007f5f;
}
.text-royal {
  color: #1a5a94;
}
.text-light-green {
  color: #4caf50;
}
.text-dark-green {
  color: #007f5f;
}
.text-yellow {
  color: #ffb300;
}
.text-orange {
  color: #ff5f00;
}
.container .bg-royal-blue:hover {
  background: #007f5f;
}
@media screen and (max-width: 600px) {
  .container .features {
    display: flex;
    flex-direction: column;
  }
  .img-hide {
    color: #1a5a94;
  }
}
/* CSS animations for each reveal direction */
.fade-in-from-top {
  animation: fadeInTop 1s ease;
}

.fade-in-from-left {
  animation: fadeInLeft 1s ease;
}

.fade-in-from-right {
  animation: fadeInRight 1s ease;
}

.fade-in-from-bottom {
  animation: fadeInBottom 1s ease;
}

/* CSS animation definitions */
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Delay for cards */
.delay-1 {
  animation-delay: 1s;
}
.delay-2 {
  animation-delay: 1.1s;
}
.delay-3 {
  animation-delay: 1.3s;
}
.delay-4 {
  animation-delay: 1.4s;
}
.delay-5 {
  animation-delay: 1.5s;
}
/* Class to make the elements initially hidden */
.appear {
  visibility: visisble;
}
</style>
